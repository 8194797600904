import React from 'react';
import { Link } from 'gatsby';
import { motion, useIsPresent } from 'framer-motion';

import Seo from '../components/Seo';
import '../styles/pages/_home.scss';

const Homepage = () => {
  const isPresent = useIsPresent();
  
  return (
    <>
      <Seo title='Home' />
      <div id='home'>
        <div className='home__name'>MARK PACIS</div>
        <div className='home__links'>
          <Link to='/projects'><motion.h1 whileHover={{ scale: 1.25, color: '#FDB927', transition: { duration: 0.25, ease: [0.34, 1.56, 0.64, 1] } }}>PROJECTS</motion.h1></Link>
          <Link to='/about'><motion.h1 whileHover={{ scale: 1.25, color: '#FDB927', transition: { duration: 0.25, ease: [0.34, 1.56, 0.64, 1] } }}>ABOUT</motion.h1></Link>
          <Link to='/contact'><motion.h1 whileHover={{ scale: 1.25, color: '#FDB927', transition: { duration: 0.25, ease: [0.34, 1.56, 0.64, 1] } }}>CONTACT</motion.h1></Link>
        </div>
        <div className='home__job'>DIGITAL DESIGNER</div>
      </div>
      <div className='bg'></div>
      <div className='bg bg2'></div>
      <div className='bg bg3'></div>
      <div className='bg4'></div>
      <motion.div
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0, transition: { duration: 0.75, ease: [0.16, 1, 0.3, 1] } }}
        exit={{ scaleY: 1, transition: { duration: 0.75, ease: [0.7, 0, 0.84, 0] } }}
        style={{ originY: isPresent ? 0 : 1 }}
        className='screen-page-transition'
      />
    </>
  )
}

export default Homepage